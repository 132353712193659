<template>
  <button
      v-if="showButtonScrollTop"
      class="btn-secondary btn-default btn_scrolltop"
      @click="scrollTop()"
  >
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M19.2806 12.9694C19.3503 13.0391 19.4056 13.1218 19.4433 13.2128C19.481 13.3039 19.5004 13.4015 19.5004 13.5C19.5004 13.5985 19.481 13.6961 19.4433 13.7872C19.4056 13.8782 19.3503 13.9609 19.2806 14.0306C19.2109 14.1003 19.1282 14.1556 19.0372 14.1933C18.9461 14.231 18.8485 14.2504 18.75 14.2504C18.6515 14.2504 18.5539 14.231 18.4628 14.1933C18.3718 14.1556 18.2891 14.1003 18.2194 14.0306L12.75 8.56031V21C12.75 21.1989 12.671 21.3897 12.5303 21.5303C12.3897 21.671 12.1989 21.75 12 21.75C11.8011 21.75 11.6103 21.671 11.4697 21.5303C11.329 21.3897 11.25 21.1989 11.25 21V8.56031L5.78062 14.0306C5.71094 14.1003 5.62822 14.1556 5.53717 14.1933C5.44613 14.231 5.34855 14.2504 5.25 14.2504C5.15145 14.2504 5.05387 14.231 4.96283 14.1933C4.87178 14.1556 4.78906 14.1003 4.71938 14.0306C4.64969 13.9609 4.59442 13.8782 4.55671 13.7872C4.51899 13.6961 4.49958 13.5985 4.49958 13.5C4.49958 13.4015 4.51899 13.3039 4.55671 13.2128C4.59442 13.1218 4.64969 13.0391 4.71938 12.9694L11.4694 6.21937C11.539 6.14964 11.6217 6.09432 11.7128 6.05658C11.8038 6.01884 11.9014 5.99941 12 5.99941C12.0986 5.99941 12.1962 6.01884 12.2872 6.05658C12.3783 6.09432 12.461 6.14964 12.5306 6.21937L19.2806 12.9694ZM20.25 3H3.75C3.55109 3 3.36032 3.07902 3.21967 3.21967C3.07902 3.36032 3 3.55109 3 3.75C3 3.94891 3.07902 4.13968 3.21967 4.28033C3.36032 4.42098 3.55109 4.5 3.75 4.5H20.25C20.4489 4.5 20.6397 4.42098 20.7803 4.28033C20.921 4.13968 21 3.94891 21 3.75C21 3.55109 20.921 3.36032 20.7803 3.21967C20.6397 3.07902 20.4489 3 20.25 3Z"
          fill="white" />
    </svg>
  </button>
</template>

<script lang="ts" setup>
const currW = useResize()
const showButtonScrollTop = ref(false)

const scrollTop = () => {
  const e = document.documentElement
  if (e) e.scrollTo({top: 0})
}

onMounted(() => {
  document.addEventListener('scroll', onScroll)
})

const onScroll = () => {
  const e = document.documentElement
  showButtonScrollTop.value = currW.value > 768 ? e.scrollTop > 3000 : e.scrollTop > 1500;
}
</script>

<style lang="scss" scoped>
@use '/assets/scss/variables.scss' as *;

.btn_scrolltop {
  position: fixed;
  right: 5vw;
  bottom: 6rem;
  z-index: 30;
  padding: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 8px;
  font-size: 1rem;
  flex-wrap: wrap;
  align-content: center;
  background: #725DF8;
  color: white;
  //@media screen and (max-width: 1800px) {
  //  right: 15vw;
  //}
  //@media screen and (max-width: 1600px) {
  //  right: 10vw;
  //}
  //@media screen and (max-width: 1600px) {
  //  right: 4rem;
  //}

  @media screen and (max-width: 979px) {
    bottom: 7rem;
  }

  i {
    width: 100%;
    height: 0.8rem;
  }
}

</style>
